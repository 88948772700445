import { useQuery } from "@tanstack/react-query";
import api from ".";
import { IContactPreferencesForm } from "../types/prefferences";
import { ITeam } from "../types/company";
import { IContact } from "../types/contact";
import properties from "./properties";
import companies from "./companies";
import {
  IAvailability,
  ICalendarEvent,
  ITeamConfiguration,
} from "../types/calendar";
import axios from "axios";
import dayjs from "dayjs";

const getPreferencesForm = async (): Promise<IContactPreferencesForm> => {
  const response = await api.get(`/forms/preferences/`);
  return response.data;
};

export const usePreferencesForm = (options = {}) => {
  const result = useQuery({
    queryKey: ["preferences-form"],
    queryFn: getPreferencesForm,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
    ...options,
  });
  return result;
};

const getTeamByCompanyProperty = async (
  companyId: string,
  propertyId: string
): Promise<ITeam[]> => {
  const response = await api.get(`/teams/`, {
    params: {
      company: companyId,
      // TODO: [tnoel - 2024-10-14] Investigate why this is not working when it's a list
      properties: propertyId,
    },
  });
  return response.data;
};

export const useTeamByCompanyProperty = (
  companyId: string,
  propertyId: string
) => {
  const result = useQuery({
    queryKey: ["team-by-company", companyId],
    queryFn: () => getTeamByCompanyProperty(companyId, propertyId),
    enabled: !!companyId,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
  });
  return result;
};

const getContact = async (contactId: string): Promise<IContact> => {
  const response = await api.get(`/contacts/${contactId}/`);
  return response.data;
};

export const useContact = (contactId: string, options = {}) => {
  const result = useQuery({
    queryKey: ["contact", contactId],
    queryFn: () => getContact(contactId),
    enabled: !!contactId,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
    ...options,
  });
  return result;
};

export const useProperties = (params: {
  slug?: string;
  company__slug?: string;
}) => {
  const result = useQuery({
    queryKey: ["properties", params.slug, params.company__slug],
    queryFn: () => properties.list(params),
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
  });
  return result;
};

export const useCompanies = (params: { slug?: string }) => {
  const result = useQuery({
    queryKey: ["companies", params?.slug],
    queryFn: () => companies.list(params),
    select: (data) => data[0],
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
  });
  return result;
};

const getEvent = async (eventId: string): Promise<ICalendarEvent> => {
  const response = await api.get(`/calendar/events/${eventId}/`);
  return response.data;
};

export const useEvent = (eventId: string) => {
  const result = useQuery({
    queryKey: ["event", eventId],
    queryFn: () => getEvent(eventId),
    enabled: !!eventId,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
  });
  return result;
};

const getTeamConfiguration = async (
  teamId: string
): Promise<ITeamConfiguration> => {
  const response = await api.get(`/nylas-scheduling-configuration/`, {
    params: {
      team: teamId,
    },
  });
  return response.data;
};

export const useTeamConfiguration = (teamId: string) => {
  const result = useQuery({
    queryKey: ["team-configuration", teamId],
    queryFn: () => getTeamConfiguration(teamId),
    enabled: !!teamId,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
  });
  return result;
};

const getDayAvailability = async (
  configurationId: string
): Promise<IAvailability> => {
  const nowTimestamp = dayjs().unix();
  const endOfMonthTimestamp = dayjs().endOf("day").unix();

  const response = await axios.get(
    "https://api.us.nylas.com/v3/scheduling/availability",
    {
      params: {
        start_time: nowTimestamp,
        end_time: endOfMonthTimestamp,
        configuration_id: configurationId,
      },
    }
  );
  return response.data;
};

export const useDayAvailability = (configurationId: string) => {
  const today = dayjs().format("DD-MM-YYYY");
  const result = useQuery({
    queryKey: ["day-availability", today, configurationId],
    queryFn: () => getDayAvailability(configurationId),
    enabled: !!configurationId,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
  });
  return result;
};

const getFututreAvailability = async (
  configurationId: string,
  numberOfDays: number
): Promise<IAvailability> => {
  const nowTimestamp = dayjs().unix();
  const endOfFutureDay = dayjs().add(numberOfDays, "day").endOf("day").unix();

  const response = await axios.get(
    "https://api.us.nylas.com/v3/scheduling/availability",
    {
      params: {
        start_time: nowTimestamp,
        end_time: endOfFutureDay,
        configuration_id: configurationId,
      },
    }
  );
  return response.data;
};

export const useFutureAvailability = (
  configurationId: string,
  numberOfDays: number
) => {
  const result = useQuery({
    queryKey: ["future-availability", numberOfDays, configurationId],
    queryFn: () => getFututreAvailability(configurationId, numberOfDays),
    enabled: !!configurationId && numberOfDays > 0,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 10,
  });
  return result;
};
